import React from 'react';
import logo from './logo.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
        	Welcome!
	  	</p>
	    <p>
	  		We&#39;re working hard to bring you a total solution for live events
        </p>
      </header>
    </div>
  );
}

export default App;
