import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as firebase from 'firebase'

/*
var config = {
	apiKey:  "AIzaSyDr-h3qXBjD37AgMModFTjbcU05GuTXtS0 ",
	authDomain: "mywave.live",
	databaseURL: "https://mywavelive.firebaseio.com",
	storageBucket: "gs://mywavelive.appspot.com",
};
*/
const firebaseConfig = {
  apiKey: "AIzaSyDr-h3qXBjD37AgMModFTjbcU05GuTXtS0",
  authDomain: "mywave.live",
  databaseURL: "https://mywavelive.firebaseio.com",
  projectId: "mywavelive",
  storageBucket: "mywavelive.appspot.com",
  messagingSenderId: "715443064417",
  appId: "1:715443064417:web:3c8be6c490630bf8546420",
  measurementId: "G-NDWF42KL95"
};
firebase.initializeApp(firebaseConfig);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
